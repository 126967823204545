.success-message {
    font-family: 'CustomFont', sans-serif;
    color: rgb(235, 235, 235);
    margin-top: 10px;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.contact-form-container {
    @apply mt-10 rounded-lg shadow-md;
}

.getInTouch {
    font-size: 3.8vw;
    font-family: 'CustomFont', sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
    color: rgb(235, 235, 235);
}

.contact-section {
    width: 55vw;
}

.contact-form {
    @apply space-y-10;
}

.form-group {
    @apply mb-4;
}

.form-label {
    font-size: 1vw;
    font-family: 'CustomFont', sans-serif;
    font-weight: 300;
    color: rgb(235, 235, 235);
}

.form-input,
.form-textarea {
    @apply mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
}

.form-button {
    @apply w-28 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-700;
}

@media (min-width: 100px) {
    .getInTouch {
        font-size: 4.3vw;
    }

    .form-label {
        font-size: 2.5vw;
    }
}

@media (min-width: 700px) {
    .getInTouch {
        font-size: 3.5vw;
    }

    .form-label {
        font-size: 1.5vw;
    }
}

@media (min-width: 1200px) {
    .getInTouch {
        font-size: 2.5vw;
    }

    .form-label {
        font-size: 1vw;
    }
}
.about-section {
    padding: 400px 0;
    /* Adds 100px of padding on top and bottom */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.content-wrapperAbout {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center content horizontally */
    height: auto;
    /* Adjust height to content */
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
    flex-direction: column;
    /* Stack elements vertically */
}

.text-containerAbout {
    margin-bottom: 50px;
    /* Adds space below the text container */
}

.titleAbout,
.descriptionAbout {
    color: rgb(235, 235, 235)
}

.titleAbout {
    font-size: 2.7vw;
    font-family: 'CustomFont', sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
}
ul{
    display: column;
    font-family: 'CustomFont', sans-serif;
    @apply list-disc;
    @apply list-inside;
    @apply space-y-4;
}

li{
    margin-bottom: 0px;
}

.descriptionAbout {
    font-family: 'CustomFont', sans-serif;
    /* font-size: 1rem; */
    font-weight: 500;
    font-size: 1.5vw;
    text-align: center;
}

@media (max-width: 800px) {
    .titleAbout {
        font-size: 7vw;
    }

    .descriptionAbout {
        font-size: 3.7vw;
    }
}

@media (max-width: 208px) {
    .content-wrapper {
        padding: 0 20px;
    }

    .titleAbout {
        font-size: 4vw;
    }

    .descriptionAbout {
        font-size: 4vw;
    }
}


@media (orientation : landscape) and (max-width: 740px) {
    .titleAbout {
            font-size: 3vw;
        }
    
        .descriptionAbout {
            font-size: 2vw;
        }
}

@media (orientation : landscape) and (max-width: 667px) {
    .titleAbout {
            font-size: 4vw;
        }
    
        .descriptionAbout {
            font-size: 2vw;
        }
}


@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  overflow-x: clip;
}

.section {
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Adjust to 100% viewport width to avoid overflow */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  color: rgb(0, 0, 0);
}
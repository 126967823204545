.spinning-circle {
    position: absolute;
    border-radius: 50%;
    border-width: 6px;
    border-style: dotted;
    border-color: rgba(179, 179, 179, 0.436);
    transform: translate(-50%, -50%);
    z-index: 10;
}


/* Base size */
.spinning-circle {
    width: 29.5rem;
    height: 29.5rem;
    top: 6.2%;
    left: 20.5%;
}

.profile-picture {
    margin-bottom: 20px;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin-right: 50px;
}

.title,
.description {
    text-align: center;
    color: rgb(235, 235, 235)
}

.title {
    display: flex;
    font-family: 'CustomFont', sans-serif;
    font-size: 2.6rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.description {
    font-family: 'CustomFont', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
}

.content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center content horizontally */
    height: 100vh;
    width: 100vw;
    padding: 0 50px;
    /* Add some padding to prevent content from touching edges */
    box-sizing: border-box;
    /* Include padding in element's total width and height */
    
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh;
    width: 100vw; */
    color: white;
    position: relative;

    
}

.profile-picture img {
    border-radius: 50%;
    /* Make the image round */
    width: 60%;
    /* Ensure the image scales with the container */
    height: auto;
    /* Maintain aspect ratio */
}

@media (max-width: 1250px) {
    .spinning-circle {
        width: 36vw;
        height: 36vw;
        top: 7%;
        left: 21%;
    }
}

/* Media Queries for Responsive Design */
@media (max-width: 1100px) {
        .spinning-circle {
                width: 36vw;
                height: 35vw;
            top: 8.7%;
            left: 20.5%;
            }
    .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4vw;
    }

    .description {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    }
}
@media (max-width: 1000px) {
    .spinning-circle {
        width: 38vw;
        height: 38vw;
        top: 3.5%;
        left: 19%;
    }
}

@media (max-width: 950px) {
    .spinning-circle {
            width: 38vw;
            height: 38vw;
            top: 7%;
            left: 20%;
    }
}

@media (max-width: 800px) {
    .spinning-circle {
                    border-width: 5px;
        width: 37.5vw;
        height: 37.5vw;
        top: 9.5%;
        left: 21%;
    }
}

@media (max-width: 700px)
and (orientation : portrait) {
    .spinning-circle {
            border-width: 5px;
            width: 50vw;
            height: 50vw;
            top: 8.6%;
            left: 21%;
        }
    .content-wrapper {
        flex-direction: column;
        /* Stack elements vertically */
        text-align: center;
        /* Center text */
    }

    .profile-picture {
        margin-right: 0;
    }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5vw;
    }

    .description {
                display: flex;
                    justify-content: center;
                    align-items: center;
        font-size: 3vw;
    }
}

@media (max-width: 600px) {
    .spinning-circle {
        width: 48vw;
        height: 48vw;
        top: 10.5%;
        left: 21.5%;
    }
}
@media (max-width: 500px) {
    .spinning-circle {
        width: 47vw;
        height: 47vw;
        top: 8.7%;
        left: 19.5%;
    }
}
@media (max-width: 400px) {
    .spinning-circle {
        width: 45vw;
        height: 45vw;
        top: 10.5%;
        left: 19%;
    }
}

@media (orientation : landscape)
and (max-width: 932px) {
    .spinning-circle {
        width: 37vw;
        height: 36vw;
        top: 9%;
        left: 20%;
    }
}

@media (orientation : landscape) and (max-width: 1024px) {
    .spinning-circle {
        width: 35.5vw;
        height: 35.5vw;
        top: 9%;
        left: 21.6%;
    }
}

@media (orientation : landscape) and (max-width: 915px) {
    .spinning-circle {
        width: 37vw;
        height: 36vw;
        top: 9%;
        left: 21.1%;
    }
}

@media (orientation : landscape) and (max-width: 844px) {
    .spinning-circle {
        width: 36.5vw;
        height: 36.5vw;
        top: 9.7%;
        left: 21.4%;
    }
}

@media (orientation : landscape) and (max-width: 740px) {
    .spinning-circle {
        width: 36.9vw;
        height: 36.9vw;
        top: 10.7%;
        left: 21.8%;
    }
}

@media (orientation : landscape) and (max-width: 667px) {
    .spinning-circle {
        width: 37.5vw;
        height: 37.5vw;
        top: 10.7%;
        left: 21.4%;
    }
}



.spinning-seporator {
    position: absolute;

    border-width: 4px;
    border-style: dotted;
    border-color: rgba(142, 140, 140, 0.241);
    transform: translate(-900%, -50%);
    z-index: 10;
}


/* Base size */
.spinning-seporator {
    width: 2000.5rem;
    height: 1.5rem;
    top: 6.2%;
    left: -400.5%;
}
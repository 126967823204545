@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/helvetica-light-587ebe5a59211.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/Helvetica.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/Helvetica-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/Helvetica-Oblique.ttf') format('truetype');
    font-weight: 400;
    font-style: oblique;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/Helvetica-BoldOblique.ttf') format('truetype');
    font-weight: 700;
    font-style: oblique;
}

@font-face {
    font-family: 'CustomFontRounded';
    src: url('./fonts/helvetica-rounded-bold-5871d05ead8de.otf') format('truetype');
    font-weight: 400;
    font-style: oblique;
}

body {
    font-family: 'CustomFont', sans-serif;
}
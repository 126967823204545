@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-x: clip;

  /* Apply globally to avoid white space issues */
}

/* Ensure root takes full height and width */
#root {
  height: 100%;
  width: 100%;
}
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color:rgba(20, 19, 20, 0.80);;
    color: rgb(235,235,235);
    padding: 0.55rem;
    z-index: 10;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(144, 143, 143, 0.455);
}

.navbar .container {
    width: 100%;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: padding .7s ease;
}


.navbar .name {
    font-family: 'CustomFont', sans-serif;
    font-size: 1.28rem;
    font-weight: 500;
}

.navbar .spacer {
    flex-grow: 1;
    /* This will take up all available space between brand and nav links */
}

.navbar .nav-links {
    display: flex;
    gap: 2.2rem;
    margin-left: auto;
    transition: margin-left 0.2s ease;
    padding-top: 8px;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid transparent;
    color: rgb(155, 155, 157);
    font-size: .8rem;
    font-weight: 300;
}

.navbar .nav-links a {
    cursor: pointer;
     transition: background-color 0.2s; transition: margin-left 0.2s ease;
}

.navbar .nav-links a:hover {
    color: rgb(235, 235, 235);
}
.navbar .nav-links .active-link {
    color: rgb(217, 216, 216);
}
.navbar .nav-link::after {
    content: '';
    position: absolute;
    transform: translateX(-46%) translateX(-22px);
    bottom: -1px;
    /* Align with the bottom border of the navbar */
    width: 5%;
    height: 1px;
    background-color: transparent;
    transition: background-color 0.3s;
}

.navbar .nav-link.active-link::after {
    background-color: rgba(255, 255, 255, 0.7);
    /* Highlighted bottom border for active link */
}

/* Media queries for different screen sizes */
@media (min-width: 200px) {
    .navbar .container {
        padding: 0 1rem;
    }

    .navbar .nav-link::after {
        width: 11%;
    }
}

/* Media queries for different screen sizes */
@media (min-width: 576px) {
    .navbar .container {
        padding: 0 1rem;
    }

        .navbar .nav-link::after {
            width: 6.5%;
        }
}

@media (min-width: 768px) {
    .navbar .container {
        padding: 0 2rem;
        /* Increase padding more */
    }
}

@media (min-width: 992px) {
    .navbar .container {
        padding: 0 9rem;
    }
    .navbar .nav-link::after {
        width: 5%;
    }
}

@media (min-width: 1200px) {
    .navbar .container {
        padding: 0 12rem;
    }
    .navbar .nav-link::after {
        width: 3.5%;
        transform: translateX(-46%) translateX(-19px);
    }
}
.carousel-container {
    position: relative;
    width: 80%;
    max-width: 30rem;
    margin: 0 auto;
}

.carousel-wrapper {
    overflow: hidden;
    position: relative;
}

.carousel-content {
    display: flex;
    transition: transform 0.5s;
}

.carousel-item {
    min-width: 100%;
}

.carousel-image {
    width: 100%;
    height: 100%;
}

.carousel-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.2rem;
    /* Reduced padding */
    background-color: rgba(31, 41, 55, 0.5);
    color: white;
    text-align: center;
}

.carousel-title {
    font-size: .9rem;
    font-weight: 600;
    margin: 0;
}

.carousel-description {
font-family: 'CustomFont', sans-serif;
font-size: .8rem;
font-weight: 300;
    margin-top: 0.25rem;
}

.carousel-link {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    /* Same size as description */
    color: #3b82f6;
    /* Tailwind's blue-500 */
    text-decoration: underline;
}

.carousel-button {
    font-size: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.5rem;
    background-color: rgba(55, 65, 81, 0.206);
    color: rgba(255, 255, 255, 0.155);
    border-radius: 9999px;
    cursor: pointer;
}
.carousel-button-left {
    left: 0;
}

.carousel-button-right {
    right: 0;
}

.carousel-indicators {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 6.5rem;
    left: 0;
    right: 0;
}

.indicator-dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: rgba(255, 255, 255, 0.157);
    border-radius: 50%;
    transition: background-color 0.3s;
}

.indicator-dot.active {
    background-color: #3b83f6ac;
}


                @media (max-width: 1024px) {
                    .carousel-container {
                            position: relative;
                            width: 80%;
                            max-width: 23rem;
                            margin: 0 auto;
                            transform: translateY(-10%);
                        }
                .carousel-indicators {
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: 1rem;
                left: 0;
                right: 0;
             }
            
                .carousel-info {
                position: absolute;
                transform: translateY(100%);
                padding: 0rem;
             }
        }  
        
        @media (max-width: 1000px) {
                 .carousel-indicators {
                     display: flex;
                     justify-content: center;
                     position: absolute;
                     bottom: 1rem;
                     left: 0;
                     right: 0;
                 }
        
                 .carousel-info {
                     position: absolute;
                     transform: translateY(100%);
                     padding: 0rem;
                 }
             }


                                                @media only screen 
                                                and (min-device-width : 896px) 
                                                and (max-device-width : 1024px) 
                                                and (orientation : landscape)  {
                                               .carousel-container {
                                                   position: relative;
                                                   width: 100%;
                                                height: 35%;
                                                   max-width: 13rem;
                                                   margin: 0 auto;
                                                   transform: translateY(-38%);
                                               }
                    
                                               .carousel-indicators {
                                                   display: flex;
                                                   justify-content: center;
                                                   position: absolute;
                                                   bottom: 1rem;
                                                   left: 0;
                                                   right: 0;
                                               }
                    
                                               .carousel-info {
                                                   position: absolute;
                                                   transform: translateY(100%);
                                                   padding: 0rem;
                                               }
                                           }

@media only screen 
and (min-device-width : 800px) 
and (max-device-width : 890px) 
and (orientation : landscape) {
    .carousel-container {
        position: relative;
        width: 100%;
        height: 26%;
        max-width: 13rem;
        margin: 0 auto;
        transform: translateY(-50%);
    }

    .carousel-indicators {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
    }

    .carousel-info {
        position: absolute;
        transform: translateY(100%);
        padding: 0rem;
    }
}

             

                @media (max-width: 820px) {
                        .carousel-container {
                            position: relative;
                            width: 100%;
                            max-width: 25rem;
                            margin: 0 auto;
                            transform: translateY(-10%);
                        }
                    .carousel-indicators {
                        display: flex;
                        justify-content: center;
                        position: absolute;
                        bottom: 1rem;
                        left: 0;
                        right: 0;
                    }
        
                    .carousel-info {
                        position: absolute;
                        transform: translateY(100%);
                        padding: 0rem;
                    }
                }

                         @media (max-width: 500px) {
                             .carousel-indicators {
                                 display: flex;
                                 justify-content: center;
                                 position: absolute;
                                 bottom: 1rem;
                                 left: 0;
                                 right: 0;
                             }
            
                             .carousel-info {
                                 position: absolute;
                                 transform: translateY(100%);
                                 padding: 0rem;
                             }
                         }
    

        @media (max-width: 400px) {
            .carousel-indicators {
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: 1rem;
                left: 0;
                right: 0;
            }
    
            .carousel-info {
                position: absolute;
                transform: translateY(100%);
                padding: 0rem;
            }
        }


                @media (max-width: 350px) {
                                .carousel-container {
                                        position: relative;
                                        width: 80%;
                                        max-width: 17rem;
                                        margin: 0 auto;
                                        transform: translateY(-10%);
                                    }
                    .carousel-indicators {
                        display: flex;
                        justify-content: center;
                        position: absolute;
                        bottom: 1rem;
                        left: 0;
                        right: 0;
                    }
        
                    .carousel-info {
                        position: absolute;
                        transform: translateY(100%);
                        padding: 0rem;
                    }
                }

                                                  @media (orientation : landscape) and (max-width: 740px) {
                                                      .carousel-container {
                                                          position: relative;
                                                          height: 25%;
                                                          max-width: 10rem;
                                                          margin: 0 auto;
                                                          transform: translateY(-10%);
                                                      }
                        
                                                      .carousel-indicators {
                                                          display: flex;
                                                          justify-content: center;
                                                          position: absolute;
                                                          bottom: .5rem;
                                                          left: 0;
                                                          right: 0;
                                                      }
                        
                                                      .carousel-info {
                                                          position: absolute;
                                                          transform: translateY(100%);
                                                          padding: 0rem;
                                                      }
                                                  }

                                @media (orientation : landscape) and (max-width: 667px) {
                            .carousel-container {
                                    position: relative;
                                    height: 25%;
                                    max-width: 10rem;
                                    margin: 0 auto;
                                    transform: translateY(-10%);
                                }
                            
                                .carousel-indicators {
                                    display: flex;
                                    justify-content: center;
                                    position: absolute;
                                    bottom: .5rem;
                                    left: 0;
                                    right: 0;
                                }
                            
                                .carousel-info {
                                    position: absolute;
                                    transform: translateY(100%);
                                    padding: 0rem;
                                }
                                }

.projects-header {
    font-size: 2.7vw;
        font-family: 'CustomFont', sans-serif;
        font-weight: 700;
        margin-bottom: 30px;
        text-align: center;
    @apply absolute top-12 transform -translate-x-1/2;
}

.contact-button-container {
    @apply absolute bottom-4 left-1/2 transform -translate-x-1/2 mb-0;
}

.contactButton {
    font-size: 1.5vw;
    @apply 
    py-2 
    px-12
    bg-sky-500
    rounded-xl shadow-md
    hover:bg-sky-400
    focus:outline-double
    focus:ring-sky-500 
    cursor-pointer;
    @apply absolute bottom-0 left-1/2 transform -translate-x-1/2 mt-4;
}

@media (max-width: 1024px), (max-height: 600) {
    .projects-header {
        font-size: 2.5vw;
        @apply absolute top-20 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-0;
    }
}

@media (max-width: 932px) {
    .projects-header {
        font-size: 2vw;
        @apply absolute top-14 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-0;
            top: 104%;
    }
}

@media only screen and (min-device-width : 800px) 
and (max-device-width : 853px) 
and (orientation : landscape) {
    .projects-header {
            font-size:1.7vw;
            @apply absolute top-16 transform -translate-x-1/2;
        }
    
        .contact-button-container {
            @apply absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-0;
            top: 104%;
        }
}


@media (max-width: 820px) {
    .projects-header {
        font-size: 3.5vw;
        @apply absolute top-20 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-4 left-1/2 transform -translate-x-1/2 mb-4;
        top: 100%;
    }
}

@media (max-width: 768px) {
    .projects-header {
        font-size: 4.5vw;
        @apply absolute top-20 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-4 left-1/2 transform -translate-x-1/2 mb-2;
    }
}

@media (max-width: 540px) {
    .projects-header {
        font-size: 4vw;
        @apply absolute top-16 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-4 left-1/2 transform -translate-x-1/2 mb-2;
    }
}

@media (max-width: 500px) {
.projects-header {
        font-size: 4.5vw;
            @apply absolute top-24 transform -translate-x-1/2;
}
.contact-button-container {
    @apply absolute bottom-4 left-1/2 transform -translate-x-1/2 mb-4;
}
.contactButton {
    font-size: 3.5vw;

}
}

@media (max-width: 430px) {
    .projects-header {
        font-size: 4.5vw;
        @apply absolute top-32 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-1 left-1/2 transform -translate-x-1/2 mb-2;
    }
}

@media (max-width: 400px) {
    .projects-header {
        font-size: 4.5vw;
        @apply absolute top-32 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-1 left-1/2 transform -translate-x-1/2 mb-2;
    }
}

@media (max-width: 390px) {
    .projects-header {
        font-size: 4.5vw;
        @apply absolute top-40 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-1 left-1/2 transform -translate-x-1/2 mb-2;
    }
}


@media (max-width: 375px) {
    .projects-header {
        font-size: 4.5vw;
        @apply absolute top-20 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-1 left-1/2 transform -translate-x-1/2 mb-6;
    }
}

@media (max-width: 350px) {
    .projects-header {
        font-size: 4.5vw;
        @apply absolute top-44 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-1 left-1/2 transform -translate-x-1/2 mb-14;
    }
}

@media (orientation : landscape) and (max-width: 740px) {
    .projects-header {
        font-size: 2.5vw;
        @apply absolute top-20 transform -translate-x-1/2;
    }

    .contact-button-container {
        @apply absolute bottom-4 left-1/2 transform -translate-x-1/2 mb-24;
        top: 130%;
    }
}

@media (orientation : landscape) and (max-width: 667px) {
    .projects-header {
            font-size: 2.5vw;
            @apply absolute top-24 transform -translate-x-1/2;
        }
    
        .contact-button-container {
            @apply absolute bottom-4 left-1/2 transform -translate-x-1/2 mb-20;
                    top: 125%;
        }
}